/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from "react"
import _ from "lodash"
import axios from "axios"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { AiFillHome as IcoHome, AiOutlineDownload as IcoDownload } from "react-icons/ai";
import "../components/Aseguradoras/aseguradoras.scss"

import spinner from '../../assets/images/spinner.svg';


export default function Aseguradora({ data, pageContext }) {
  const [downloadFiles, setDownloadFiles] = useState([])
  const [loading, setLoading] = useState(true)
  const { aseguradora } = data
  const { databaseId } = pageContext
  const imgSrc = getImage(aseguradora.aseguradorasCustomFields.image.localFile)
  const { web, direccion, telefono, informacionNumerosDeCuentas, informacionSugese } = aseguradora.aseguradorasCustomFields
  useEffect(() => {
    const fetch = async () => {
      let files = []
      try {
        const response = await axios.get(`https://bcq.pnl.mybluehost.me/wp-json/acf/v3/aseguradoras/${databaseId}`);
        const fields = response.data.acf
        _.reduce(fields, (acc, value, key) => {
          const currentForm = `formulario_descargable_${acc}`
          if (key === currentForm) {
            value && files.push(value)
            acc++
          }
          return acc
        }, 1)
        setDownloadFiles(files)
      } catch (error) {
        setDownloadFiles(files)
      }
      setLoading(false)
    }
    fetch()
  }, [databaseId])

  const handleDownloadFiles = () => {
    if (loading) return (<img width={60} src={spinner} alt="Cargando" />)
    if (_.isEmpty(downloadFiles) && !loading) return null
    return (
      <div className="descargas">
        <h3>Archivos Descargables</h3>
        <ul>
          {_.map(downloadFiles, file => {
            return (
              <li><a href={file.url} target="_blank" rel="noreferrer"><IcoDownload /> {file.title}</a></li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <div className="page-aseguradoras container">
      <Helmet>
        <title>Uniserse Costa Rica - {aseguradora.title}</title>
        <meta name="description" content={direccion} />
      </Helmet>
      <div className="breadcrum"><Link to="/"><IcoHome /> Regresar</Link></div>
      <div className="resumen">
        <GatsbyImage image={imgSrc} alt={aseguradora.title} layout="fixed" width={150} className="resume-img" />
        <div className="resume-content">
          <h1>{aseguradora.title}</h1>
          <ul>
            <li><strong>Web:</strong><a href={web} target="_blank" rel="noreferrer">{web}</a></li>
            <li><strong>Dirección:</strong>{direccion}</li>
            <li><strong>Teléfono:</strong><a href={`+tel:${telefono}`}>{telefono}</a></li>
          </ul>
        </div>
      </div>
      {handleDownloadFiles()}
      <div className="tablas-informativas">
        <div className="informacion-sugese" dangerouslySetInnerHTML={{ __html: informacionSugese }} />
        <div className="informacion-numeros-de-cuentas" dangerouslySetInnerHTML={{ __html: informacionNumerosDeCuentas }} />
      </div>
    </div>
  )
}

export const query = graphql`
  query($id: String!) {
    aseguradora: wpAseguradora(id: { eq: $id }) {
      id
      title
      aseguradorasCustomFields {
        direccion
        informacionNumerosDeCuentas
        informacionSugese
        telefono
        web
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 150)
            }
          }
        }
      }
    }
  }
`;